import React from "react"
import * as Chakra from "@chakra-ui/react"
import { useInView } from "react-intersection-observer"
import { HeaderState } from "../header-state"
import mergeRefs from "react-merge-refs"
import { getPositionOfElement } from "../animation-helpers/animation-state"
import useScrollPosition from "@react-hook/window-scroll";

export const SectionWithLogo = React.forwardRef(({ children }, ref) => {
  const { ref: inViewRef, inView } = useInView({ threshold: 0.0000000000000001 })

  const layoutRef = React.useRef()


  const pos = useScrollPosition(5)

  React.useEffect(() => {
    if (inView && !HeaderState.state.showLogo) {
      let { top } = getPositionOfElement(layoutRef)
      if (top <= 0) {
        HeaderState.showLogo()
      }
    } else if (inView && HeaderState.state.showLogo) {
      let { top } = getPositionOfElement(layoutRef)
      if (top > 0) {
        setTimeout(() => HeaderState.hideLogo(), 400)
      }
    } else {
      HeaderState.hideLogo()
    }
    return () => {
      HeaderState.hideLogo()
    }
  }, [pos, inView])

  return (
    <Chakra.Box
      width="100%"
      ref={mergeRefs([inViewRef, ref, layoutRef])}
      paddingTop="140px"
      backgroundColor="#000"
      sx={{
        scrollSnapAlign: "start",

      }}
      css={{
        "@media (max-width: 767px)": {
          paddingTop: "20px",
        },
      }}
    >
      {children}
    </Chakra.Box>
  )
})

SectionWithLogo.pressless = {
  name: "Section With Logo",
  attributes: {},
  hasChildren: true,
}
