import React from "react"
import * as Chakra from "@chakra-ui/react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import mergeRefs from "react-merge-refs"
import LayeredLogoSvg from "../../images/sutori-layered-logo.svg"
import { Parallax } from "../animation-helpers/animation-state"
import {ResponsiveVideoImage} from "../video-image";


interface HeroImageProps {
  heroDetails?: {
    topic_text: string
    brand: string
    background_image: any
    mobile_background_image: any
    video_url: string
    mobile_video_url: string
  }[]
}

const HeroSlider = React.forwardRef((props: HeroImageProps, ref) => {
  const [pause, setPause] = React.useState(false)
  const timer = React.useRef()
  const [opacities, setOpacities] = React.useState([])
  const [sliderRef, slider] = useKeenSlider({
    slides: props.heroDetails ? props.heroDetails.length : 0,
    loop: true,
    duration: 1000,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
    move(s) {
      const new_opacities = s.details().positions.map((slide) => slide.portion)
      setOpacities(new_opacities)
    },
  })


  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 4000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])


  return (
      <Chakra.Box ref={ref} height="100vh"
                  width="100%" overflow="hidden"
                  position="relative" css={{
        marginTop: "-140px",
        "@media (max-width: 992px)": {
          marginTop: "-140px"
        },
        "@media (max-width: 767px)": {
          marginTop: "-100px"
        }
      }}
                  sx={{
                    scrollSnapAlign: "start",
                  }}>
      <Chakra.Box
        ref={sliderRef as React.RefObject<HTMLDivElement>}
        height="100vh"
        width="100%"
        className="fader"
        overflow="hidden"
        position="relative"

      >
        {props?.heroDetails &&
          props.heroDetails.map(
            ({ topic_text, brand, background_image, video_url, mobile_background_image, mobile_video_url }, idx) => (
                <Chakra.Box
                width="100%"
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                height="100vh"
                position="absolute"
                top="0px"
                className="fader__slide"
                style={{ opacity: opacities[idx] }}
                key={idx}
              >
                <Chakra.Box
                  width="100%"
                  display="flex"
                  position="relative"
                  alignItems="flex-start"
                  flexDirection="column"

                  css={{
                    "@media (max-width: 767px)": {
                      opacity: "1!important",
                    },
                  }}
                  transition="1s"
                >
                  <ResponsiveVideoImage video_url={video_url} mobile_video_url={mobile_video_url} image={background_image} mobile_background_image={mobile_background_image} />
                  <Chakra.Box
                    left="0"
                    right="0"
                    width="100%"
                    bottom="120px"
                    display="flex"
                    position="absolute"
                    maxWidth="1440px"
                    alignItems="center"
                    marginLeft="auto"
                    marginRight="auto"
                    paddingLeft="58px"
                    paddingRight="58px"
                    flexDirection="column"
                    justifyContent="center"
                    css={{
                      "@media (max-width: 991px)": {
                        bottom: "59px",
                        paddingLeft: "24px",
                        paddingRight: "24px",
                      },
                    }}
                  >
                    <Chakra.Box
                      width="100%"
                      maxWidth="1134px"
                      maxHeight="174px"
                      objectFit="cover"
                      css={{
                        "@media (max-width: 991px)": {
                          maxHeight: "52px",
                          overflow: "unset",
                        },
                      }}
                      overflow="hidden"
                    >
                      <Parallax y={[0, 200]} width="100%">
                        <Chakra.Box
                          width="100%"
                          maxWidth="1134px"
                          maxHeight="174px"
                          objectFit="cover"
                          css={{
                            "@media (max-width: 991px)": {
                              maxHeight: "52px",
                            },
                          }}
                        >
                          <LayeredLogoSvg height="100%" width="auto" />
                        </Chakra.Box>
                      </Parallax>
                    </Chakra.Box>
                    <Chakra.Box
                      width="100%"
                      display="flex"
                      marginTop="46px"
                      alignItems="center"
                      justifyContent="space-between"
                      css={{
                        "@media (max-width: 991px)": {
                          marginTop: "106px",
                          justifyContent: "center",
                        },
                      }}
                    >
                      <Chakra.Box
                        as="span"
                        color="#fff"
                        fontSize="11px"
                        fontFamily="TradeGothic LT Extended"
                        lineHeight="12px"
                        letterSpacing="1.8px"
                        textTransform="uppercase"
                        css={{
                          "@media (max-width: 991px)": {
                            textAlign: "center",
                          },
                        }}
                      >
                        {brand}
                      </Chakra.Box>
                      <Chakra.Box
                        as="span"
                        color="#fff"
                        fontSize="11px"
                        fontFamily="TradeGothic LT Extended"
                        lineHeight="12px"
                        letterSpacing="1.8px"
                        textTransform="uppercase"
                        css={{
                          "@media (max-width: 991px)": {
                            display: "none",
                          },
                        }}
                      >
                        {topic_text}
                      </Chakra.Box>
                    </Chakra.Box>
                  </Chakra.Box>
                </Chakra.Box>
              </Chakra.Box>
            )
          )}
      </Chakra.Box>
      </Chakra.Box>
  )
})

HeroSlider.pressless = {
  name: "Hero Slider",
  attributes: {
    heroDetails: {
      name: "Slides",
      type: "Repeater",
      attributes: {
        topic_text: {
          name: "Brand Details",
          type: "RichText",
          placeholder: "Current Reel — Vans",
        },
        brand: {
          name: "Brand",
          type: "RichText",
          placeholder: "Cinema for your brand",
        },
        background_image: {
          name: "Background Image",
          type: "Image",
        },
        video_url: {
          name: "Video (Desktop)",
          type: "PlainText",
        },
        mobile_background_image: {
          name: "Mobile Background Image",
          type: "Image",
        },
        mobile_video_url: {
          name: "Video (Mobile)",
          type: "PlainText",
        },
      },
    },
  },
}

export default HeroSlider
