import React from "react"
import * as Chakra from "@chakra-ui/react"
import LayeredLogoSvg from "../../images/sutori-layered-logo.svg"

interface BlockslayeredLogoProps {
  image?: any
}

const LayeredLogo = React.forwardRef((props: BlockslayeredLogoProps, ref) => {
  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      height="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
    >
      <Chakra.Box
        width="100%"
        height="100%"
        display="flex"
        maxWidth="1440px"
        alignItems="center"
        marginLeft="auto"
        marginRight="auto"
        paddingLeft="58px"
        paddingRight="58px"
        flexDirection="column"
        css={{
          "@media (max-width: 991px)": {
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "0px",
          },
        }}
      >
        <Chakra.Box
          width="100%"
          maxWidth="1134px"
          marginTop="-34px"
          objectFit="cover"
          css={{
            "@media (max-width: 991px)": {
              maxWidth: "341px",
              marginTop: "-20px",
            },
          }}
        />
        <LayeredLogoSvg height="100%" width="auto" />
      </Chakra.Box>
    </Chakra.Box>
  )
})

LayeredLogo.pressless = {
  name: "Layered Logo",
  attributes: {},
}
export default LayeredLogo
