import React from "react"
import * as Chakra from "@chakra-ui/react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { HiCheck } from "react-icons/hi"
import { AiFillCaretDown } from "react-icons/ai"
import {PressLessImage} from "../gatsby-image-svg-fallback";

interface BlockscontactFormProps {
  image?: any
  callDetails?: string
  instagramDetails?: string
  submit?: string
  formHeading?: string
  twitterDetails?: string
  emailDetails?: string
  call?: string
  twitter?: string
}

const ContactForm = React.forwardRef((props: BlockscontactFormProps, ref) => {
  const data = useStaticQuery(graphql`
    {
      site {
        buildTime
      }
      wp {
        acfOptionsOptions {
          siteOptions {
            formContactOptions {
              label
            }
            instagramUsername
            instagramUrl
            twitterUsername
            twitterUrl
            vimeoUsername
            vimeoUrl
            phoneNumber
            email
          }
        }
      }
    }
  `)

  const [fullName, setFullName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [contactNumber, setContactNumber] = React.useState("")
  const [option, setOption] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSuccessful, setIsSuccessful] = React.useState(false)

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setIsLoading(true)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "Contact Form",
        fullName: fullName,
        email: email,
        contactNumber: contactNumber,
        option: option,
        message: message,
        pageUrl: window.location.href,
      }),
    })
      .then((res) => {
        setIsSuccessful(true)

        setTimeout(() => {
          navigate("/thank-you")
        }, 1500)
      })
      .catch((error) => alert(error))
      .finally(() => setIsLoading(false))
  }

  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="#000"
    >
      <PressLessImage
        image={props.image}
        width="100%"
        display="none!important"
        maxHeight="248px"
        minHeight="248px"
        objectFit="cover"
        css={{
          "@media (max-width: 991px)": {
            display: "block!important",
          },
        }}
      />
      <Chakra.Box
        color="#fff"
        width="100%"
        display="flex"
        maxWidth="1440px"
        alignItems="flex-start"
        marginLeft="auto"
        paddingTop="46px"
        marginRight="auto"
        paddingLeft="58px"
        paddingRight="58px"
        paddingBottom="53px"
        justifyContent="space-between"
        css={{
          "@media (max-width: 991px)": {
            width: "100%",
            maxWidth: "100%",
            paddingTop: "60px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "12px",
          },
        }}
      >
        <Chakra.Box
          width="100%"
          display="flex"
          maxWidth="100%"
          alignItems="flex-start"
          paddingRight="68px"
          justifyContent="space-between"
          css={{
            "@media (max-width: 991px)": {
              paddingRight: "0px",
              flexDirection: "column",
            },
          }}
        >
          <Chakra.Box
            width="40%"
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            css={{
              "@media (max-width: 991px)": {
                width: "100%",
                maxWidth: "100%",
                minWidth: "100%",
                flexDirection: "row",
              },
            }}
          >
            <Chakra.Box
              width="100%"
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
              paddingBottom="48px"
              justifyContent="flex-start"
            >
              <Chakra.Box
                as="span"
                fontSize="11px"
                fontFamily="TradeGothic LT Extended"
                lineHeight="12px"
                letterSpacing="2px"
                paddingBottom="26px"
                textTransform="uppercase"
              >
                Contact
              </Chakra.Box>
              <Chakra.Box
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                paddingBottom="15px"
              >
                <Chakra.Box
                  as="span"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="22px"
                  paddingBottom="2px"
                >
                  Email
                </Chakra.Box>
                <Chakra.Box
                  as="a"
                  color="#9d9d9d"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="22px"
                  paddingBottom="2px"
                  css={{
                    "@media (max-width: 479px)": {
                      paddingRight: "6px",
                    },
                  }}
                  href={`mailto:${data?.wp?.acfOptionsOptions?.siteOptions?.email}`}
                >
                  {data?.wp?.acfOptionsOptions?.siteOptions?.email}
                </Chakra.Box>
              </Chakra.Box>
              <Chakra.Box
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
              >
                <Chakra.Box
                  as="span"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="22px"
                  paddingBottom="2px"
                >
                  Call us
                </Chakra.Box>
                <Chakra.Box
                  as="a"
                  color="#9d9d9d"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="22px"
                  paddingBottom="2px"
                  href={`tel:${data?.wp?.acfOptionsOptions?.siteOptions?.phoneNumber}`}
                >
                  {data?.wp?.acfOptionsOptions?.siteOptions?.phoneNumber}
                </Chakra.Box>
              </Chakra.Box>
            </Chakra.Box>
            <Chakra.Box
              width="100%"
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
              paddingBottom="48px"
              justifyContent="flex-start"
            >
              <Chakra.Box
                as="span"
                fontSize="11px"
                fontFamily="TradeGothic LT Extended"
                lineHeight="12px"
                letterSpacing="2px"
                paddingBottom="26px"
                textTransform="uppercase"
              >
                Follow us
              </Chakra.Box>
              {data?.wp?.acfOptionsOptions?.siteOptions?.instagramUrl && <Chakra.Box
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                paddingBottom="15px"
              >
                <Chakra.Box
                  as="span"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="22px"
                  paddingBottom="2px"
                >
                  Instagram
                </Chakra.Box>
                <Chakra.Box
                  as="a"
                  color="#9d9d9d"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="22px"
                  paddingBottom="2px"
                  href={data?.wp?.acfOptionsOptions?.siteOptions?.instagramUrl}
                >
                  {data?.wp?.acfOptionsOptions?.siteOptions?.instagramUsername}
                </Chakra.Box>
              </Chakra.Box>}
              {data?.wp?.acfOptionsOptions?.siteOptions?.vimeoUrl && <Chakra.Box
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                paddingBottom="15px"
              >
                <Chakra.Box
                  as="span"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="22px"
                  paddingBottom="2px"
                >
                  Vimeo
                </Chakra.Box>
                <Chakra.Box
                  as="a"
                  color="#9d9d9d"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="22px"
                  paddingBottom="2px"
                  href={data?.wp?.acfOptionsOptions?.siteOptions?.vimeoUrl}
                >
                  {data?.wp?.acfOptionsOptions?.siteOptions?.vimeoUsername}
                </Chakra.Box>
              </Chakra.Box>}
              {data?.wp?.acfOptionsOptions?.siteOptions?.twitterUrl && <Chakra.Box
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
              >
                <Chakra.Box
                  as="span"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="22px"
                  paddingBottom="2px"
                >
                  Twitter
                </Chakra.Box>
                <Chakra.Box
                  as="a"
                  color="#9d9d9d"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="22px"
                  paddingBottom="2px"
                  href={data?.wp?.acfOptionsOptions?.siteOptions?.twitterUrl}
                >
                  {data?.wp?.acfOptionsOptions?.siteOptions?.twitterUsername}
                </Chakra.Box>
              </Chakra.Box>}
            </Chakra.Box>
          </Chakra.Box>
          <Chakra.Box
            width="60%"
            display="flex"
            paddingLeft="16px"
            flexDirection="column"
            css={{
              "@media (max-width: 991px)": {
                width: "100%",
                maxWidth: "100%",
                minWidth: "100%",
                paddingLeft: "0px",
              },
            }}
          >
            <Chakra.Box
              as="span"
              fontSize="11px"
              fontFamily="TradeGothic LT Extended"
              lineHeight="12px"
              letterSpacing="2px"
              paddingBottom="26px"
              textTransform="uppercase"
            >
              {props.formHeading}
            </Chakra.Box>
            <Chakra.Box
              width="100%"
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
            >
              <form
                style={{ width: "100%" }}
                onSubmit={handleSubmit}
                data-netlify="true"
                name="Contact Form"
                method="post"
              >
                <Chakra.Input
                  placeholder="Your Name"
                  width="100%"
                  fontSize="14px"
                  background="transparent"
                  borderTop="0px"
                  borderLeft="0px"
                  lineHeight="22px"
                  borderColor="var(--dl-color-gray-black)"
                  borderRight="0px"
                  paddingLeft="0px"
                  py="20px"
                  css={{
                    borderRadius: "0px !important",
                    "::placeholder": { color: "white" },
                  }}
                  marginBottom="22px"
                  color="white"
                  cursor="auto"
                  __placeholder={{
                    paddingLeft: "0px",
                    marginBottom: "10px !important",
                    color: "white",
                  }}
                  borderWidth="1px"
                  backgroundColor="var(--dl-color-gray-white)"
                  value={fullName}
                  name="fullName"
                  onChange={(e) => setFullName(e.target.value)}
                  isRequired
                />
                <Chakra.Input
                  placeholder="Email"
                  width="100%"
                  fontSize="14px"
                  background="transparent"
                  borderTop="0px"
                  borderLeft="0px"
                  lineHeight="22px"
                  borderColor="var(--dl-color-gray-black)"
                  borderRight="0px"
                  paddingLeft="0px"
                  borderRadius="4px"
                  py="20px"
                  css={{
                    borderRadius: "0px !important",
                    "::placeholder": { color: "white" },
                  }}
                  __placeholder={{
                    paddingLeft: "0px",
                    marginBottom: "10px !important",
                    color: "white",
                  }}
                  marginBottom="22px"
                  color="var(--dl-color-gray-black)"
                  cursor="auto"
                  borderWidth="1px"
                  backgroundColor="var(--dl-color-gray-white)"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  isRequired
                />
                <Chakra.Input
                  placeholder="Contact Number"
                  width="100%"
                  fontSize="14px"
                  background="transparent"
                  borderTop="0px"
                  borderLeft="0px"
                  lineHeight="22px"
                  borderColor="white"
                  borderRight="0px"
                  paddingLeft="0px"
                  borderRadius="4px"
                  marginBottom="22px"
                  color="var(--dl-color-gray-black)"
                  cursor="auto"
                  py="20px"
                  css={{
                    borderRadius: "0px !important",
                    "::placeholder": { color: "white" },
                  }}
                  __placeholder={{
                    paddingLeft: "0px",
                    marginBottom: "10px !important",
                    color: "white",
                  }}
                  borderWidth="1px"
                  backgroundColor="var(--dl-color-gray-white)"
                  value={contactNumber}
                  name="contactNumber"
                  onChange={(e) => setContactNumber(e.target.value)}
                  isRequired
                />
                <Chakra.Select
                  icon={
                    <Chakra.Box
                      width="10px!important"
                      marginRight="-28px"
                      height="10px!important"
                      my="auto"
                    >
                      <AiFillCaretDown
                        style={{ width: "10px", height: "10px" }}
                      />
                    </Chakra.Box>
                  }
                  sx={{
                    textAlignLast: "left",
                    paddingInlineStart: 0,
                    paddingInlineEnd: 0,
                  }}
                  size="lg"
                  type="text"
                  placeholder="I'm getting in touch about"
                  maxHeight="72px"
                  height="42px"
                  width="100%"
                  css={{
                    borderRadius: "0px !important",
                    "::placeholder": { color: "black" },
                  }}
                  fontSize="14px"
                  background="transparent"
                  borderTop="0px"
                  borderLeft="0px"
                  lineHeight="22px"
                  borderColor="var(--dl-color-gray-black)"
                  borderRight="0px"
                  paddingLeft="0px"
                  borderRadius="4px"
                  marginBottom="22px"
                  color="white"
                  cursor="auto"
                  padding="0.5rem 0rem"
                  borderWidth="1px"
                  name="option"
                  onChange={(e) => setOption(e.target.value)}
                  isRequired
                >
                  {data?.wp?.acfOptionsOptions?.siteOptions?.formContactOptions?.map(
                    ({ label }) => (
                      <option value={label} style={{ color: "black" }}>
                        {label}
                      </option>
                    )
                  )}
                </Chakra.Select>
                <Chakra.Textarea
                  placeholder="Your message here"
                  width="100%"
                  css={{
                    borderRadius: "0px !important",
                    "::placeholder": { color: "white" },
                  }}
                  height="auto"
                  fontSize="14px"
                  background="transparent"
                  marginTop="2px"
                  minHeight="133px"
                  lineHeight="22px"
                  borderColor="var(--dl-color-gray-black)"
                  borderRadius="4px"
                  marginBottom="28px"
                  color="var(--dl-color-gray-black)"
                  cursor="auto"
                  padding="0.5rem"
                  borderWidth="1px"
                  backgroundColor="var(--dl-color-gray-white)"
                  value={message}
                  name="message"
                  onChange={(e) => setMessage(e.target.value)}
                  isRequired
                />
                <Chakra.Button
                  type="submit"
                  isDisabled={isLoading || isSuccessful}
                  padding="0.5rem 1rem"
                  d="block"
                  ml="auto"
                  pr="0"
                  fontSize="11px"
                  alignSelf="flex-end"
                  fontFamily="TradeGothic LT Extended"
                  lineHeight="12px"
                  border="none"
                  letterSpacing="2.06px"
                  textTransform="uppercase"
                  color="#fff"
                  display="inline-block"
                  borderColor="var(--dl-color-gray-black)"
                  backgroundColor="var(--dl-color-gray-white)"
                >
                  {isSuccessful && (
                    <Chakra.Box width="100%" css={{ svg: { margin: "auto" } }}>
                      <HiCheck />
                    </Chakra.Box>
                  )}
                  {isLoading && (
                    <Chakra.Box width="100%">
                      <Chakra.Spinner size="sm" />
                    </Chakra.Box>
                  )}
                  {!isLoading && !isSuccessful && (
                    <Chakra.Text>{props.submit}</Chakra.Text>
                  )}
                </Chakra.Button>
              </form>
            </Chakra.Box>
          </Chakra.Box>
        </Chakra.Box>
        <PressLessImage
          image={props.image}
          width="100%"
          height="auto"
          maxHeight="417px"
          minHeight="417px"
          objectFit="cover"
          css={{
            "@media (max-width: 991px)": {
              display: "none!important",
            },
          }}
        />
      </Chakra.Box>
    </Chakra.Box>
  )
})
ContactForm.pressless = {
  name: "Contact Form",
  attributes: {
    image: {
      name: "image",
      type: "Image",
    },
    submit: {
      name: "Submit Text",
      type: "RichText",
      placeholder: "Submit Enquiry",
    },
    formHeading: {
      name: "Form Title",
      type: "RichText",
      placeholder: "Get in touch",
    },
  },
}

export default ContactForm
