import React from "react"
import * as Chakra from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { wordpressFlatMenuToHierarchical } from "../utils/wordpress-flat-menu-to-hierarchical"
import { useLocation } from "@reach/router"

function wordpressLink(link) {
  return link.endsWith("/") && link.length > 1
    ? link.substring(0, link.length - 1)
    : link
}

const CtaCategorySelector = () => {
  const location = useLocation()

  const data = useStaticQuery(graphql`
    query CtaCategoryQuery {
      site {
        buildTime
      }
      allWpCategory(sort: {fields: postOrder}) {
        nodes {
          name
          count
        }
      }
      allWpMenu(filter: { locations: { eq: GATSBY_FOOTER_MENU } }) {
        nodes {
          menuItems {
            nodes {
              label
              url
              parentId
              id
            }
          }
        }
      }
      allWpPost {
        totalCount
      }
      wp {
        acfOptionsOptions {
          siteOptions {
            email
            phoneNumber
            categoryColours {
              categoryName
              colour
              fieldGroupName
            }
          }
        }
      }
    }
  `)

  const {categoryColours} = data.wp.acfOptionsOptions.siteOptions
  const colors = [
    "#5bdcb9",
    "#ffd461",
    "#c089f8",
    "#ff94c0",
    "#9493f0",
    "#feaa6b",
    "#83d7fa",
    "#fff",
  ]

  const menu = React.useMemo(
    () =>
      wordpressFlatMenuToHierarchical(
        data?.allWpMenu?.nodes[0].menuItems?.nodes
      ),
    []
  )

  return (
    <Chakra.Box
      width="100%"
      display="flex"
      position="relative"
      background="#000"
      alignItems="flex-start"
      flexDirection="column"
    >
      <Chakra.Box
        width="100%"
        display="flex"
        maxWidth="1440px"
        alignItems="flex-start"
        marginLeft="auto"
        paddingTop="58px"
        marginRight="auto"
        paddingLeft="58px"
        paddingRight="58px"
        flexDirection="column"
        paddingBottom="19px"
        css={{
          "@media (max-width: 991px)": {
            paddingTop: "50px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "29px",
          },
        }}
      >
        <Chakra.Box
          width="100%"
          display="flex"
          flexFlow="wrap"
          alignItems="flex-start"
          flexDirection="row"
          justifyContent="center"
          css={{
            "@media (max-width: 991px)": {
              flexDirection: "column",
              justifyContent: "flex-start",
            },
          }}
        >
          <Chakra.Button
            as={Link}
            to="/"
            display="flex"
            alignItems="flex-end"
            paddingRight="20px"
            paddingBottom="20px"
            color={{ base: "#fff" }}
            opacity="0.3"
            _hover={{ opacity: "1" }}
            transition="opacity 0.5s, color 0.5s"
            cursor="pointer"
            justifyContent="flex-start"
            css={{
              "@media (max-width: 991px)": {
                width: "100%",
                display: "flex",
                paddingBottom: "5px",
              },
            }}
          >
            <Chakra.Box
              as="h1"
              fontSize="42px"
              fontFamily="TradeGothic LT Extended"
              lineHeight="36px"
              letterSpacing="2.94px"
              textTransform="uppercase"
              css={{
                "@media (max-width: 991px)": {
                  width: "100%",
                  display: "inline",
                  padding: "0px",
                  fontSize: "26px",
                  lineHeight: "36px",
                  letterSpacing: "1.82px",
                },
                "@media (max-width: 479px)": {
                  width: "auto",
                },
              }}
            >
              All
            </Chakra.Box>
            <Chakra.Box
              as="span"
              fontSize="13px"
              lineHeight="15px"
              paddingLeft="7px"
              letterSpacing="0.5px"
              css={{
                "@media (max-width: 991px)": {
                  lineHeight: "28px",
                },
              }}
            >
              ({data.allWpPost.totalCount})
            </Chakra.Box>
          </Chakra.Button>
          {menu &&
            menu.map((item, index) => (
              <Chakra.Button
                key={index}
                as={Link}
                to={item.url}
                display="flex"
                alignItems="flex-end"
                paddingRight="20px"
                paddingBottom="20px"

                color={
                  wordpressLink(location.pathname) === wordpressLink(item.url)
                    ? (categoryColours.find(x => x.categoryName === item.label) ? categoryColours.find(x => x.categoryName === item.label).colour : "#fff")
                    : "#fff"
                }
                opacity={{
                  base: wordpressLink(location.pathname) === wordpressLink(item.url)
                      ? "1"
                      : "0.3",
                  md:
                    wordpressLink(location.pathname) === wordpressLink(item.url)
                      ? "1"
                      : "0.3",
                }}
                _hover={{ color: categoryColours.find(x => x.categoryName === item.label) ? categoryColours.find(x => x.categoryName === item.label).colour : "#fff", opacity: "1" }}
                _active={{ color: categoryColours.find(x => x.categoryName === item.label) ? categoryColours.find(x => x.categoryName === item.label).colour : "#fff", opacity: "1" }}
                transition="opacity 0.5s, color 0.5s"
                cursor="pointer"
                justifyContent="flex-start"
                css={{
                  "@media (max-width: 991px)": {
                    width: "100%",
                    display: "flex",
                    paddingBottom: "5px",
                    justify:'flex-start',
                    align:'flex-start'
                  },
                }}
              >
                <Chakra.Box
                  as="h1"
                  fontSize="42px"
                  fontFamily="TradeGothic LT Extended"
                  lineHeight="36px"
                  letterSpacing="2.94px"
                  textTransform="uppercase"
                  css={{
                    "@media (max-width: 991px)": {
                      width: "100%",
                      display: "inline",
                      padding: "0px",
                      fontSize: "26px",
                      lineHeight: "36px",
                      letterSpacing: "1.82px",
                    },
                    "@media (max-width: 479px)": {
                      width: "100%",
                      whiteSpace:'pre-wrap',
                        textAlign:'left'
                    },
                  }}
                >
                  {item.label}
                </Chakra.Box>
                {data.allWpCategory.nodes
                  .filter((node) => node.name == item.label)
                  .map((node, index) => (
                    <Chakra.Box
                      as="span"
                      key={index}
                      fontSize="13px"
                      lineHeight="15px"
                      paddingLeft="7px"
                      letterSpacing="0.5px"
                      css={{
                        "@media (max-width: 991px)": {
                          lineHeight: "28px",
                        },
                      }}
                    >
                      ({node.count ? node.count : 0})
                    </Chakra.Box>
                  ))}
              </Chakra.Button>
            ))}
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
}

export default CtaCategorySelector
