import React from 'react';
import * as Chakra from '@chakra-ui/react';
import {PressLessImage} from "./gatsby-image-svg-fallback";

export interface VideoImageProps {
    video_url: string
    mobile_video_url: string
    image: any
    mobile_background_image: any
}

export const ResponsiveVideoImage = ({video_url, mobile_video_url, image, mobile_background_image}: VideoImageProps) => {

    return (
        <>
            <Chakra.Box
                width="100%"
                position="relative"
                height="100vh"
                css={{
                    "@media (max-width: 767px)": {
                        display: mobile_video_url ? "none" : "block"
                    },
                }}
            >
                <PressLessImage position="absolute" width="100%" top="0" height="100%" image={image}/>
                {video_url && <Chakra.Box
                    height="100%"
                    backgroundRepeat="no-repeat"
                    position="absolute"
                    as="video"
                    top="0"
                    backgroundSize="cover"
                    backgroundPosition="center center"
                    id="background-video"
                    objectFit="cover"
                    objectPosition="center center"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    playsInline
                    autoPlay
                    loop
                    muted
                    width="100%"
                >
                    <source src={video_url} type="video/mp4"/>
                    <source src={video_url} type="video/ogg"/>
                    Your browser does not support the video tag.
                </Chakra.Box>}
            </Chakra.Box>
            {mobile_background_image && <Chakra.Box
                width="100%"
                position="absolute"
                top="0"
                height="100vh"
                display="none"
                css={{
                    "@media (max-width: 767px)": {
                        display: "block"
                    },
                }}
            >
                <PressLessImage position="absolute" top="0" width="100%" height="100%" image={mobile_background_image}/>
                {mobile_video_url && <Chakra.Box
                    height="100%"
                    top="0"
                    backgroundRepeat="no-repeat"
                    as="video"
                    position="absolute"
                    backgroundSize="cover"
                    backgroundPosition="center center"
                    id="background-video"
                    objectFit="cover"
                    objectPosition="center center"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    playsInline
                    autoPlay
                    loop
                    muted
                    width="100%"
                >
                    <source src={mobile_video_url} type="video/mp4"/>
                    <source src={mobile_video_url} type="video/ogg"/>
                    Your browser does not support the video tag.
                </Chakra.Box>}
            </Chakra.Box>}
        </>

    )


}
