import React, {useEffect} from "react"
import * as Chakra from "@chakra-ui/react"
import Play from "../images/Play.svg"
import { WPImage } from "./gatsby-image-svg-fallback"
import { Link } from "gatsby"
import GifPlayer from "./gif-player/index"



/*interface VideoCardProps {
  image_alt?: string
  rootClassName?: string
  image_src?: string
  image_alt1?: string
  image_src1?: string
}*/





const VideoCard = React.forwardRef(({ image, src, title, category, gallery, type, to, ...props }, ref) => {




  return (

      <Chakra.Box
        height="100%"
        display="flex"
        position="relative"
        borderRadius="4px"
        transform="translateZ(0px)"
        alignItems="flex-start"
        flexDirection="column"
        backgroundColor="#000000"
        width="100%"
        pb={{ base: "20px", lg: "0px" }}
        as={Link}
        to={to}
        {...props}
          ref={ref}
      >
        <Chakra.Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          backgroundColor="#D9D9D9"
          width="100%"
          css={{
            "@media (max-width: 479px)": {
              width: "100%",
            },
          }}
        >
          <Chakra.Box width="100%" backgroundColor="#000">
            <Chakra.Box
              w="100%"
              h="100%"
              overflow="hidden"
              backgroundColor="#222"
              borderRadius="4px"
            >


               {
                 type == "gif"
                 ?
                     <WPImage
                         w="100%"
                         h="100%"
                         objectFit="cover"
                         src={src}
                         {...image}
                         borderRadius="4px"
                         transform="translateZ(0px)"
                     />
                :
                <WPImage
                w="100%"
                h="100%"
                objectFit="cover"
                src={src}
                {...image}
                borderRadius="4px"
                transform="translateZ(0px)"
              />
               }
            </Chakra.Box>
          </Chakra.Box>
        </Chakra.Box>
        <Chakra.Box
          display="flex"
          width="100%"
          alignItems="flex-start"
          paddingTop="15px"
          flexDirection="row"
        >
          <Chakra.Box width="19.35px" height="16px" pt="2px">
            <Play w="100%" h="100%" />
          </Chakra.Box>
          <Chakra.VStack width="100%" justify="start" align="start">
            <Chakra.Box
              as="span"
              color="#ffffff"
              fontSize="11px"
              fontFamily="TradeGothic LT Extended"
              lineHeight="16px"
              textTransform="uppercase"
            >
              {title}
            </Chakra.Box>
            <Chakra.Box
              as="span"
              color="#ffffff"
              fontSize="12px"
              fontFamily="Inter"
              lineHeight="14.52px"
              letterSpacing="0.6px"
            >
              {category}
            </Chakra.Box>
          </Chakra.VStack>
        </Chakra.Box>
      </Chakra.Box>

  )
})

export default VideoCard
