import React from "react"
import * as Chakra from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"

const CategorySelector = ({ handleFilter, selectedCategory }) => {
  const data = useStaticQuery(graphql`
    query CategoryQuery {
      site {
        buildTime
      }
      allWpCategory(sort: {fields: termOrder___order}) {
        nodes {
          name
          count
          postOrder
        }
      }
      allWpPost {
        totalCount
      }
      wp {
        acfOptionsOptions {
          siteOptions {
            email
            phoneNumber
            categoryColours {
              categoryName
              colour
              fieldGroupName
            }
          }
        }
      }
    }
  `)


  const {categoryColours} = data.wp.acfOptionsOptions.siteOptions

  const colors = [
    "#5bdcb9",
    "#ffd461",
    "#c089f8",
    "#ff94c0",
    "#9493f0",
    "#feaa6b",
    "#83d7fa",
    "#fff",
  ]

  return (
    <Chakra.Box
      width="100%"
      display="flex"
      position="relative"
      background="#000"
      alignItems="flex-start"
      flexDirection="column"
    >
      <Chakra.Box
        width="100%"
        display="flex"
        maxWidth="1440px"
        alignItems="flex-start"
        marginLeft="auto"
        paddingTop="58px"
        marginRight="auto"
        paddingLeft="58px"
        paddingRight="58px"
        flexDirection="column"
        paddingBottom="19px"
        css={{
          "@media (max-width: 991px)": {
            paddingTop: "50px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "29px",
          },
        }}
      >
        <Chakra.Box
          width="100%"
          display="flex"
          flexFlow="wrap"
          alignItems="flex-start"
          flexDirection="row"
          justifyContent="center"
          css={{
            "@media (max-width: 991px)": {
              flexDirection: "column",
              justifyContent: "flex-start",
            },
          }}
        >
          <Chakra.Button
            display="flex"
            alignItems="flex-end"
            paddingRight="20px"
            paddingBottom="20px"
            onClick={() => handleFilter("All")}
            color={{ base: "#fff" }}
            opacity={{ base: "1", md: selectedCategory === "All" ? "1" : "0.3" }}
            _hover={{ opacity: "1" }}
            transition="opacity 0.5s, color 0.5s"
            cursor="pointer"
            justifyContent="flex-start"
            css={{
              "@media (max-width: 991px)": {
                width: "auto",
                display: "flex",
                paddingBottom: "5px",
              },
            }}
          >
            <Chakra.Box
              as="h1"
              fontSize="42px"
              fontFamily="TradeGothic LT Extended"
              lineHeight="36px"
              letterSpacing="2.94px"
              textTransform="uppercase"
              css={{
                "@media (max-width: 991px)": {
                  width: "100%",
                  display: "inline",
                  padding: "0px",
                  fontSize: "26px",
                  lineHeight: "36px",
                  letterSpacing: "1.82px",
                },
                "@media (max-width: 479px)": {
                  width: "auto",
                },
              }}
            >
              All
            </Chakra.Box>
            <Chakra.Box
              as="span"
              fontSize="13px"
              lineHeight="15px"
              paddingLeft="7px"
              letterSpacing="0.5px"
              css={{
                "@media (max-width: 991px)": {
                  lineHeight: "28px",
                },
              }}
            >
              ({data.allWpPost.totalCount})
            </Chakra.Box>
          </Chakra.Button>
          {data &&
            data.allWpCategory.nodes
              .filter((node) => node.name != "Uncategorized")
              .map((node, index) => (
                <Chakra.Button
                  key={index}
                  display="flex"
                  alignItems="flex-end"
                  paddingRight="20px"
                  paddingBottom="20px"
                  onClick={() => handleFilter(node.name)}
                  color={{ base: categoryColours.find(x => x.categoryName === node.name) ? categoryColours.find(x => x.categoryName === node.name).colour : "#fff", md: selectedCategory === node.name ?  categoryColours.find(x => x.categoryName === node.name) ? categoryColours.find(x => x.categoryName === node.name).colour : "#fff" : "#fff" }}
                  opacity={{ base: "1", md: selectedCategory === node.name ?  "1" : "0.3" }}
                  _hover={{
                    color: categoryColours.find(x => x.categoryName === node.name) ? categoryColours.find(x => x.categoryName === node.name).colour : "#fff",
                    opacity: "1",
                  }}
                  _active={{ color: categoryColours.find(x => x.categoryName === node.name) ? categoryColours.find(x => x.categoryName === node.name).colour : "#fff", opacity: "1" }}
                  transition="opacity 0.5s, color 0.5s"
                  cursor="pointer"
                  justifyContent="flex-start"
                  css={{
                    "@media (max-width: 991px)": {
                      width: "auto",
                      display: "flex",
                      paddingBottom: "5px",
                    },
                    "@media (max-width: 460px)": {
                      width: "100%",
                      display: "flex",
                      paddingBottom: "5px",
                      justify:'flex-start',
                      align:'flex-start'
                    },
                  }}
                >
                  <Chakra.Box
                    as="h1"
                    fontSize="42px"
                    fontFamily="TradeGothic LT Extended"
                    lineHeight="36px"
                    letterSpacing="2.94px"
                    textTransform="uppercase"
                    css={{
                      "@media (max-width: 991px)": {
                        width: "100%",
                        display: "inline",
                        padding: "0px",
                        fontSize: "26px",
                        lineHeight: "36px",
                        letterSpacing: "1.82px",
                      },
                      "@media (max-width: 479px)": {
                        width: "100%",
                        whiteSpace:'pre-wrap',
                        textAlign:'left'
                      },
                    }}
                  >
                    {node.name}
                  </Chakra.Box>
                  <Chakra.Box
                    as="span"
                    fontSize="13px"
                    lineHeight="15px"
                    paddingLeft="7px"
                    letterSpacing="0.5px"
                    css={{
                      "@media (max-width: 991px)": {
                        lineHeight: "28px",
                      },
                    }}
                  >
                    ({node.count ? node.count : 0})
                  </Chakra.Box>
                </Chakra.Button>
              ))}
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
}

export default CategorySelector
