import React from "react"
import { useInView } from "react-intersection-observer"
import useScrollPosition from "@react-hook/window-scroll"
import * as Chakra from "@chakra-ui/react"

interface RenderBlocksProps {
  blocks: any[]
  postType: string
}

const RenderBlocks = ({
  blocks,
  postType,
}: RenderBlocksProps): React.ReactElement[] => {
  if (!blocks || !Array.isArray(blocks) || !postType) {
    return
  }

  blocks = blocks.map(
    (
      { fieldGroupName, ...el }: { fieldGroupName: string; el: any },
      i: number
    ) => {
      if (!fieldGroupName) return null

      fieldGroupName = fieldGroupName.replace(postType, "")

      const block = {
        //_Acf_ContentBlocks_AnimatedHero: AnimatedHero,
      }[fieldGroupName]

      if (!block) return null

      if (fieldGroupName !== "_Acf_ContentBlocks_AnimatedHero" && i > 2) {
        // @ts-ignore
        return (
          <ScrollFadeIn>
            {React.createElement(block, { ...el, key: i })}
          </ScrollFadeIn>
        )
      }

      // @ts-ignore
      return React.createElement(block, { ...el, key: i })
    }
  )

  return blocks
}

export default RenderBlocks

export const ScrollFadeIn = ({ initialInView = false, ...props }) => {
  const [isSsr, setIsSsr] = React.useState(true)

  const { ref, inView } = useInView({
    rootMargin: "128px",
    threshold: 0.2,
    initialInView: isSsr ? true : initialInView,
  })

  React.useEffect(() => {
    setIsSsr(false)
  }, [])

  return (
    <Chakra.Box width="100%" overflow="hidden" backgroundColor="#000">
      <Chakra.Box
        ref={ref}
        width="100%"
        transition="opacity 0.7s"
        style={{
          opacity: inView ? 1 : 0,
        }}
        {...props}
      />
    </Chakra.Box>
  )
}
