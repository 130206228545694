import React from "react"
import * as Chakra from "@chakra-ui/react"
import { HeaderState } from "../header-state"
import {PressLessImage} from "../gatsby-image-svg-fallback";

interface BlockspageEndCtaProps {
  black_text?: string
  colored_text?: string
  white_text?: string
  image?: any,
    mobileImage?: any
}

const PageEndCta = React.forwardRef((props: BlockspageEndCtaProps, ref) => {
  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      height="100%"
      maxHeight="686px"
      display="flex"
      position="relative"
      flexDirection="column"
      css={{
        "@media (max-width: 767px)": {
          minHeight: "600px",
        },
      }}
    >
      <Chakra.Flex
        flexGrow={1}
        width="100%"
        maxHeight="686px"
        position="relative"
      >
        <PressLessImage
          image={props.image}
          width="100%"
          position="relative"
          objectFit="cover"
          css={{
            "@media (max-width: 767px)": {
              display: "none!important"
            }
          }}
        />
        <PressLessImage
            image={props.mobileImage}
            width="100%"
            position="relative"
            objectFit="cover"
            display="none!important"
            css={{
              "@media (max-width: 767px)": {
                display: "initial!important"
              }
            }}
        />
      </Chakra.Flex>

      <Chakra.Box
        display="flex"
        width="100%"
        bottom="80px"
        position="absolute"
        alignItems="center"
        flexDirection="row"
        justifyContent="flex-end"
        css={{
          "@media (max-width: 767px)": {
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <Chakra.Box
          maxWidth="654px"
          color="white"
          fontSize="22px"
          fontStyle="normal"
          textAlign="center"
          fontWeight="500"
          lineHeight="34px"
          bg="transparent"
          letterSpacing="1.5px"
          margin="auto"
          css={{
            "@media (max-width: 767px)": {
              fontSize: "20px",
              maxWidth: "338px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "28px",
              letterSpacing: "1.36px",
            },
            "@media (max-width: 479px)": {
              marginLeft: "24px",
              marginRight: "24px",
            },
          }}
        >
          {props.black_text}
          <Chakra.Text
            as="span"
            cursor="pointer"
            transition="0.3s"
            _hover={{ opacity: 0.5 }}
            color="#5BDCB9"
            onClick={() => HeaderState.showDrawer()}
          >
            {props.colored_text}
          </Chakra.Text>
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
})

PageEndCta.pressless = {
  name: "Page End CTA",
  attributes: {
    black_text: {
      name: "Main Text",
      type: "RichText",
    },
    colored_text: {
      name: "Linked Text",
      type: "RichText",
    },
    image: {
      name: "Background Image",
      type: "Image",
    },
      mobileImage: {
        name: "Mobile Background Image",
          type: "Image"
      }
  },
}
export default PageEndCta
