import React from "react"
import * as Chakra from "@chakra-ui/react"
import {PressLessImage} from "../gatsby-image-svg-fallback";

interface BlockslogoGridProps {
  logos: { logo: any }[]
  heading?: string,
  subtitle?: string
}

const LogoGrid = React.forwardRef((props: BlockslogoGridProps, ref) => {
  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="#000000"
    >
      <Chakra.Box
        width="100%"
        display="flex"
        maxWidth="1138px"
        marginLeft="auto"
        paddingTop="48px"
        marginRight="auto"
        paddingLeft="58px"
        paddingRight="58px"
        flexDirection="column"
        paddingBottom="66px"
        justifyContent="flex-start"
        backgroundColor="#000000"
      >
        <Chakra.Box
          width="100%"
          display="flex"
          alignSelf="flex-start"
          alignItems="center"
          flexDirection="column"
          paddingBottom="80px"
          css={{
            "@media (max-width: 767px)": {
              paddingBottom: "40px",
            },
          }}
        >
          <Chakra.Box
            as="h1"
            color="#ffffff"
            fontSize="36px"
            fontStyle="normal"
            textAlign="center"
            fontWeight="500"
            lineHeight="30px"
            letterSpacing="1.8px"
            paddingBottom="37px"
            css={{
              "@media (max-width: 991px)": {
                fontSize: "22px",
                letterSpacing: "1.1px",
                paddingBottom: "26px",
              },
            }}
          >
            {props?.heading}
          </Chakra.Box>
          <Chakra.Box
            as="span"
            color="#ffffff"
            fontSize="22px"
            fontStyle="normal"
            textAlign="center"
            fontWeight="300"
            lineHeight="22px"
            css={{
              "@media (max-width: 991px)": {
                fontSize: "14px",
              },
            }}
          >
            {props?.subtitle}
            <Chakra.Box
              as="span"
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </Chakra.Box>
        </Chakra.Box>
        <Chakra.Grid
          width="100%"
          templateColumns={{
            base: "repeat(3,1fr)",
            md: "repeat(3,1fr)",
            lg: "repeat(4,1fr)",
            xl: "repeat(6,1fr)",
          }}
          gap={{ base: "40px", md: "60px", xl: "80px" }}
          pb={{ base: "40px", md: "80px" }}
        >
          {props?.logos &&
            props.logos.map(({ logo }) => (
              <Chakra.Flex alignItems="center" justifyContent="center" width="100%">
                <PressLessImage
                  image={logo}
                  maxWidth="135px"
                  maxHeight="135px"
                  objectFit="contain"
                />
              </Chakra.Flex>
            ))}
        </Chakra.Grid>
      </Chakra.Box>
    </Chakra.Box>
  )
})
LogoGrid.pressless = {
  name: "Logo Grid",
  attributes: {
    logos: {
      name: "Logos",
      type: "Repeater",
      attributes: {
        logo: {
          name: "Logo",
          type: "Image",
        },
      }
    },
    heading: {
      name: "Heading",
      type: "RichText",
      placeholder: "Heading",
    },
    subtitle: {
      name: "Subtitle",
      type: "RichText",
      placeholder: "Subtitle",
    }
  },
}
export default LogoGrid
