import React from "react"
import * as Chakra from "@chakra-ui/react"
import VideoCard from "../video-card"
import PhotographyCard from "../photography-card"
import CategorySelector from "../category-selector"
import { useStaticQuery, graphql } from "gatsby"
import { ScrollFadeIn } from "../../utils/render-blocks"
import { Masonry } from "masonic"
import {useInView} from "react-intersection-observer";
import FadeTransition from "../transitions/fade-transition";

const MasonryGrid = React.forwardRef((_, ref) => {
  const data = useStaticQuery(graphql`
    query CtaPostsQuery {
      site {
        buildTime
      }
      allWpPost(sort: { fields: date }) {
        nodes {
          postOrder
          date
          link
          title
          featuredImage {
            node {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
          postGallery {
            fieldGroupName
            primaryContentType
          }
        }
      }
    }
  `)

  const [posts] = React.useState(
    data.allWpPost.nodes.sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
  )
  const [selectedCategory, setSelectedCategory] = React.useState("All")
  const [categories, setFilteredCategories] = React.useState(posts)

  const [visible, setVisible] = React.useState(14)

  const [seenIndexes, setSeenIndexes] = React.useState([])

  const showMore = () => {
    setVisible((prevValue) => prevValue + 14)
  }

  const handleFilter = (e) => {
    if (e == "All") {
      setSelectedCategory(e)
      setFilteredCategories(posts)
    } else {
      setSelectedCategory(e)
      setFilteredCategories(
        posts.filter((post) =>
          post.categories.nodes.map((x) => x.name).includes(e)
        )
      )
    }
  }

  const visiblePosts = categories.slice(0, visible).map(x => ({...x, setSeen: setSeenIndexes, seen: seenIndexes}))

  return (
    <Chakra.Box width="100%" backgroundColor="#000">
      <ScrollFadeIn>
        <CategorySelector
          handleFilter={handleFilter}
          selectedCategory={selectedCategory}
        />
      </ScrollFadeIn>
      <FadeTransition shouldChange={selectedCategory}>
      <Chakra.Box
        width="100%"
        height="100%"
        display="flex"
        position="relative"
        minHeight="100%"
        alignItems="flex-start"
        flexDirection="column"
        backgroundColor="#000000"
        css={{ overflowAnchor: "none" }}
      >
        <Chakra.Box
          display="flex"
          width="100%"
          height="100%"
          maxWidth="1440px"
          alignItems="flex-start"
          marginLeft="auto"
          paddingTop="47px"
          marginRight="auto"
          paddingLeft="58px"
          paddingRight="58px"
          flexDirection="column"
          paddingBottom="142px"
          justifyContent="space-between"
          backgroundColor="#000000"
          minHeight="100%"
          css={{
            "@media (max-width: 991px)": {
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingBottom: "90px",
            },
          }}
        >
          <Chakra.Box display={{base: "none", lg: "unset"}} width="100%">
            <Masonry
                key={selectedCategory}
                render={MasonryCard}
                items={visiblePosts}
                columnGutter={18}
                columnCount={3}
                overscanBy={4}
            />
          </Chakra.Box>
          <Chakra.Box display={{lg: "none"}} width="100%">
            <Masonry
                key={selectedCategory}
                render={MasonryCard}
                items={visiblePosts}
                columnGutter={18}
                columnCount={1}
                overscanBy={4}
            />
          </Chakra.Box>

          {categories.length > 14 && categories[visible] !== undefined && (
            <Chakra.Button
              color="var(--dl-color-gray-black)"
              display="inline-block"
              marginLeft="auto"
              marginTop="100px"
              borderColor="var(--dl-color-gray-black)"
              marginRight="auto"
              paddingLeft="63px"
              borderRadius="4px"
              minWidth="214px"
              paddingRight="63px"
              flexDirection="row"
              paddingBottom="12px"
              onClick={() => showMore()}
              textTransform="uppercase"
              backgroundColor="var(--dl-color-gray-white)"
              padding="0.5rem 1rem"
              borderWidth="1px"
              textAlign="center"
              fontSize="13px"
              lineHeight="15.73px"
              letterSpacing="1.3px"
              cursor="pointer"
            >
              Load More
            </Chakra.Button>
          )}
        </Chakra.Box>
      </Chakra.Box>
      </FadeTransition>
    </Chakra.Box>
  )
})

const MasonryCard = ({ index, data }) => {
  const animation = Chakra.keyframes`
    0% {
      opacity: 0;
      transform: translateY(20px)
    }
    100% {
      opacity: 1;
      transform: translateY(0px)
    }
  `
  const category = data

  const { ref, inView } = useInView({triggerOnce: true})

  React.useEffect(() => {
    if (inView) {
      setTimeout(() => {
        data.setSeen((prev)=> [...prev, index])
      }, 800)
    }
  }, [inView])


  if (category.postGallery.primaryContentType === "video") {
    return (
      <VideoCard
          ref={ref}
        key={`${category.link}`}
        itemKey={category.link}
        src={category.featuredImage.node?.localFile?.publicURL}
        image={category.featuredImage.node}
        title={category.title}
        category={category.categories.nodes[0].name}
        gallery={category.postGallery.gallery}
        to={category.link}
        type={category.postGallery.primaryContentType}
          animation={{ lg: inView && !data.seen.includes(index) ? `${animation} 0.6s forwards` : "none" }}
        opacity={{lg: data.seen.includes(index) ? "1" : "0"}}
        animationIterationCount={1}
      />
    )
  } else if (category.postGallery.primaryContentType === "gif") {
    return (
      <VideoCard
          ref={ref}
        key={`${category.link}`}
        itemKey={category.link}
        src={category.featuredImage.node?.localFile?.publicURL}
        image={category.featuredImage.node}
        title={category.title}
        category={category.categories.nodes[0].name}
        gallery={category.postGallery.gallery}
        to={category.link}
        type={category.postGallery.primaryContentType}
          animation={{ lg: inView && !data.seen.includes(index) ? `${animation} 0.6s forwards` : "none" }}
          opacity={{lg: data.seen.includes(index) ? "1" : "0"}}
        animationIterationCount={1}
      />
    )
  } else {
    return (
      <PhotographyCard
          ref={ref}
        key={`${category.link}`}
        itemKey={category.link}
        to={category.link}
        src={category.featuredImage.node?.localFile?.publicURL}
        image={category.featuredImage.node}
        title={category.title}
        category={category.categories.nodes[0].name}
        gallery={category.postGallery.gallery}
        type={category.postGallery.primaryContentType}
          animation={{ lg: inView && !data.seen.includes(index) ? `${animation} 0.6s forwards` : "none" }}
          opacity={{lg: data.seen.includes(index) ? "1" : "0"}}
        animationIterationCount={1}
      />
    )
  }
}

MasonryGrid.displayName = "MasonryGrid"

MasonryGrid.pressless = {
  name: "Masonry Grid",
  attributes: {},
}
export default MasonryGrid
