import React from "react"
import * as Chakra from "@chakra-ui/react"

interface BlockscontentSetProps {
  topicText?: string
  content?: string
}

const IntroBlock = React.forwardRef((props: BlockscontentSetProps, ref) => {

    const keyframes = Chakra.keyframes`
      25% {
        box-shadow: 0vmax 0vmax 10vmax 10vmax #9493f0, 15vmax 0vmax 10vmax 10vmax #feaa6b, 30vmax 0vmax 10vmax 10vmax #83d7fa, 45vmax 0vmax 10vmax 10vmax #fff, 60vmax 0vmax 10vmax 10vmax #5bdcb9, 75vmax 0vmax 10vmax 10vmax #ffd461, 90vmax 0vmax 10vmax 10vmax #c089f8, 105vmax 0vmax 10vmax 10vmax #ff94c0, 120vmax 0vmax 10vmax 10vmax #9493f0, 135vmax 0vmax 10vmax 10vmax #feaa6b, 0vmax 15vmax 10vmax 10vmax #83d7fa, 15vmax 15vmax 10vmax 10vmax #fff, 30vmax 15vmax 10vmax 10vmax #5bdcb9, 45vmax 15vmax 10vmax 10vmax #ffd461, 60vmax 15vmax 10vmax 10vmax #c089f8, 75vmax 15vmax 10vmax 10vmax #ff94c0, 90vmax 15vmax 10vmax 10vmax #9493f0, 105vmax 15vmax 10vmax 10vmax #feaa6b, 120vmax 15vmax 10vmax 10vmax #83d7fa, 135vmax 15vmax 10vmax 10vmax #fff, 0vmax 30vmax 10vmax 10vmax #5bdcb9, 15vmax 30vmax 10vmax 10vmax #5bdcb9, 30vmax 30vmax 10vmax 10vmax #ffd461, 45vmax 30vmax 10vmax 10vmax #c089f8, 60vmax 30vmax 10vmax 10vmax #ff94c0, 75vmax 30vmax 10vmax 10vmax #9493f0, 90vmax 30vmax 10vmax 10vmax #feaa6b, 105vmax 30vmax 10vmax 10vmax #83d7fa, 120vmax 30vmax 10vmax 10vmax #fff, 135vmax 30vmax 10vmax 10vmax #5bdcb9, 0vmax 45vmax 10vmax 10vmax #ffd461, 15vmax 45vmax 10vmax 10vmax #c089f8, 30vmax 45vmax 10vmax 10vmax #ff94c0, 45vmax 45vmax 10vmax 10vmax #9493f0, 60vmax 45vmax 10vmax 10vmax #feaa6b, 75vmax 45vmax 10vmax 10vmax #83d7fa, 90vmax 45vmax 10vmax 10vmax #fff, 105vmax 45vmax 10vmax 10vmax #5bdcb9, 120vmax 45vmax 10vmax 10vmax #5bdcb9, 135vmax 45vmax 10vmax 10vmax #ffd461, 0vmax 60vmax 10vmax 10vmax #c089f8, 15vmax 60vmax 10vmax 10vmax #ff94c0, 30vmax 60vmax 10vmax 10vmax #9493f0, 45vmax 60vmax 10vmax 10vmax #feaa6b, 60vmax 60vmax 10vmax 10vmax #83d7fa, 75vmax 60vmax 10vmax 10vmax #fff, 90vmax 60vmax 10vmax 10vmax #5bdcb9, 105vmax 60vmax 10vmax 10vmax #ffd461, 120vmax 60vmax 10vmax 10vmax #c089f8, 135vmax 60vmax 10vmax 10vmax #ff94c0; }
      45% {
        box-shadow: 0vmax 0vmax 10vmax 10vmax #83d7fa, 15vmax 0vmax 10vmax 10vmax #fff, 30vmax 0vmax 10vmax 10vmax #5bdcb9, 45vmax 0vmax 10vmax 10vmax #ffd461, 60vmax 0vmax 10vmax 10vmax #c089f8, 75vmax 0vmax 10vmax 10vmax #ff94c0, 90vmax 0vmax 10vmax 10vmax #9493f0, 105vmax 0vmax 10vmax 10vmax #feaa6b, 120vmax 0vmax 10vmax 10vmax #83d7fa, 135vmax 0vmax 10vmax 10vmax #fff, 0vmax 15vmax 10vmax 10vmax #5bdcb9, 15vmax 15vmax 10vmax 10vmax #ffd461, 30vmax 15vmax 10vmax 10vmax #c089f8, 45vmax 15vmax 10vmax 10vmax #ff94c0, 60vmax 15vmax 10vmax 10vmax #9493f0, 75vmax 15vmax 10vmax 10vmax #feaa6b, 90vmax 15vmax 10vmax 10vmax #83d7fa, 105vmax 15vmax 10vmax 10vmax #fff, 120vmax 15vmax 10vmax 10vmax #5bdcb9, 135vmax 15vmax 10vmax 10vmax #ffd461, 0vmax 30vmax 10vmax 10vmax #c089f8, 15vmax 30vmax 10vmax 10vmax #ff94c0, 30vmax 30vmax 10vmax 10vmax #9493f0, 45vmax 30vmax 10vmax 10vmax #feaa6b, 60vmax 30vmax 10vmax 10vmax #83d7fa, 75vmax 30vmax 10vmax 10vmax #fff, 90vmax 30vmax 10vmax 10vmax #5bdcb9, 105vmax 30vmax 10vmax 10vmax #5bdcb9, 120vmax 30vmax 10vmax 10vmax #ffd461, 135vmax 30vmax 10vmax 10vmax #c089f8, 0vmax 45vmax 10vmax 10vmax #ff94c0, 15vmax 45vmax 10vmax 10vmax #9493f0, 30vmax 45vmax 10vmax 10vmax #feaa6b, 45vmax 45vmax 10vmax 10vmax #83d7fa, 60vmax 45vmax 10vmax 10vmax #fff, 75vmax 45vmax 10vmax 10vmax #5bdcb9, 90vmax 45vmax 10vmax 10vmax #ffd461, 105vmax 45vmax 10vmax 10vmax #c089f8, 120vmax 45vmax 10vmax 10vmax #ff94c0, 135vmax 45vmax 10vmax 10vmax #9493f0, 0vmax 60vmax 10vmax 10vmax #feaa6b, 15vmax 60vmax 10vmax 10vmax #83d7fa, 30vmax 60vmax 10vmax 10vmax #fff, 45vmax 60vmax 10vmax 10vmax #5bdcb9, 60vmax 60vmax 10vmax 10vmax #5bdcb9, 75vmax 60vmax 10vmax 10vmax #ffd461, 90vmax 60vmax 10vmax 10vmax #c089f8, 105vmax 60vmax 10vmax 10vmax #ff94c0, 120vmax 60vmax 10vmax 10vmax #9493f0, 135vmax 60vmax 10vmax 10vmax #feaa6b; }
      75% {
        box-shadow: 0vmax 0vmax 10vmax 10vmax #c089f8, 15vmax 0vmax 10vmax 10vmax #ff94c0, 30vmax 0vmax 10vmax 10vmax #9493f0, 45vmax 0vmax 10vmax 10vmax #feaa6b, 60vmax 0vmax 10vmax 10vmax #83d7fa, 75vmax 0vmax 10vmax 10vmax #fff, 90vmax 0vmax 10vmax 10vmax #5bdcb9, 105vmax 0vmax 10vmax 10vmax #ffd461, 120vmax 0vmax 10vmax 10vmax #c089f8, 135vmax 0vmax 10vmax 10vmax #ff94c0, 0vmax 15vmax 10vmax 10vmax #9493f0, 15vmax 15vmax 10vmax 10vmax #feaa6b, 30vmax 15vmax 10vmax 10vmax #83d7fa, 45vmax 15vmax 10vmax 10vmax #fff, 60vmax 15vmax 10vmax 10vmax #5bdcb9, 75vmax 15vmax 10vmax 10vmax #5bdcb9, 90vmax 15vmax 10vmax 10vmax #ffd461, 105vmax 15vmax 10vmax 10vmax #c089f8, 120vmax 15vmax 10vmax 10vmax #ff94c0, 135vmax 15vmax 10vmax 10vmax #9493f0, 0vmax 30vmax 10vmax 10vmax #feaa6b, 15vmax 30vmax 10vmax 10vmax #83d7fa, 30vmax 30vmax 10vmax 10vmax #fff, 45vmax 30vmax 10vmax 10vmax #5bdcb9, 60vmax 30vmax 10vmax 10vmax #ffd461, 75vmax 30vmax 10vmax 10vmax #c089f8, 90vmax 30vmax 10vmax 10vmax #ff94c0, 105vmax 30vmax 10vmax 10vmax #9493f0, 120vmax 30vmax 10vmax 10vmax #feaa6b, 135vmax 30vmax 10vmax 10vmax #83d7fa, 0vmax 45vmax 10vmax 10vmax #fff, 15vmax 45vmax 10vmax 10vmax #5bdcb9, 30vmax 45vmax 10vmax 10vmax #5bdcb9, 45vmax 45vmax 10vmax 10vmax #ffd461, 60vmax 45vmax 10vmax 10vmax #c089f8, 75vmax 45vmax 10vmax 10vmax #ff94c0, 90vmax 45vmax 10vmax 10vmax #9493f0, 105vmax 45vmax 10vmax 10vmax #feaa6b, 120vmax 45vmax 10vmax 10vmax #83d7fa, 135vmax 45vmax 10vmax 10vmax #fff, 0vmax 60vmax 10vmax 10vmax #5bdcb9, 15vmax 60vmax 10vmax 10vmax #ffd461, 30vmax 60vmax 10vmax 10vmax #c089f8, 45vmax 60vmax 10vmax 10vmax #ff94c0, 60vmax 60vmax 10vmax 10vmax #9493f0, 75vmax 60vmax 10vmax 10vmax #feaa6b, 90vmax 60vmax 10vmax 10vmax #83d7fa, 105vmax 60vmax 10vmax 10vmax #fff, 120vmax 60vmax 10vmax 10vmax #5bdcb9, 135vmax 60vmax 10vmax 10vmax #5bdcb9; }
    `

  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
    >
      <Chakra.Box
        width="100%"
        margin="auto"
        display="flex"
        maxWidth="1440px"
        alignItems="center"
        paddingTop="87px"
        paddingLeft="58px"
        paddingRight="58px"
        flexDirection="column"
        paddingBottom="94px"
        justifyContent="center"
        css={{
          "@media (max-width: 767px)": {
            paddingTop: "26px",
            paddingLeft: "28px",
            paddingRight: "28px",
            paddingBottom: "60px",
          },
          "@media (max-width: 479px)": {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        <Chakra.Box
          width="100%"
          fontSize="60px"
          maxWidth="1015px"
          fontStyle="normal"
          textAlign="center"
          fontWeight="700"
          lineHeight="74px"
          fontFamily="TradeGothic LT Extended"
          textTransform="uppercase"
          css={{
              "& b": {
                  color: "white",
                  backgroundColor: "black",
                  display: "inline-flex",
                  position: "relative",
                  overflow: "hidden"
              },
            "& u": {
                mixBlendMode: "darken",
                color: "white",
                backgroundColor: "black",
                display: "inline-flex",
                position: "relative",
                textDecoration: "none",
                filter: "blur(0.6px)",
            },
              "& b:before": {
                  "content": "''",
                  "display": "block",
                  "position": "absolute",
                  "borderRadius": "50%",
                  "boxShadow": "0vmax 0vmax 10vmax 10vmax #5bdcb9, 15vmax 0vmax 10vmax 10vmax #ffd461, 30vmax 0vmax 10vmax 10vmax #c089f8, 45vmax 0vmax 10vmax 10vmax #ff94c0, 60vmax 0vmax 10vmax 10vmax #9493f0, 75vmax 0vmax 10vmax 10vmax #feaa6b, 90vmax 0vmax 10vmax 10vmax #83d7fa, 105vmax 0vmax 10vmax 10vmax #fff, 120vmax 0vmax 10vmax 10vmax #5bdcb9, 135vmax 0vmax 10vmax 10vmax #ffd461, 0vmax 15vmax 10vmax 10vmax #c089f8, 15vmax 15vmax 10vmax 10vmax #ff94c0, 30vmax 15vmax 10vmax 10vmax #9493f0, 45vmax 15vmax 10vmax 10vmax #feaa6b, 60vmax 15vmax 10vmax 10vmax #83d7fa, 75vmax 15vmax 10vmax 10vmax #fff, 90vmax 15vmax 10vmax 10vmax #5bdcb9, 105vmax 15vmax 10vmax 10vmax #ffd461, 120vmax 15vmax 10vmax 10vmax #c089f8, 135vmax 15vmax 10vmax 10vmax #ff94c0, 0vmax 30vmax 10vmax 10vmax #9493f0, 15vmax 30vmax 10vmax 10vmax #feaa6b, 30vmax 30vmax 10vmax 10vmax #83d7fa, 45vmax 30vmax 10vmax 10vmax #fff, 60vmax 30vmax 10vmax 10vmax #5bdcb9, 75vmax 30vmax 10vmax 10vmax #ffd461, 90vmax 30vmax 10vmax 10vmax #c089f8, 105vmax 30vmax 10vmax 10vmax #ff94c0, 120vmax 30vmax 10vmax 10vmax #9493f0, 135vmax 30vmax 10vmax 10vmax #feaa6b, 0vmax 45vmax 10vmax 10vmax #83d7fa, 15vmax 45vmax 10vmax 10vmax #fff, 30vmax 45vmax 10vmax 10vmax #5bdcb9, 45vmax 45vmax 10vmax 10vmax #5bdcb9, 60vmax 45vmax 10vmax 10vmax #ffd461, 75vmax 45vmax 10vmax 10vmax #c089f8, 90vmax 45vmax 10vmax 10vmax #ff94c0, 105vmax 45vmax 10vmax 10vmax #9493f0, 120vmax 45vmax 10vmax 10vmax #feaa6b, 135vmax 45vmax 10vmax 10vmax #83d7fa, 0vmax 60vmax 10vmax 10vmax #fff, 15vmax 60vmax 10vmax 10vmax #5bdcb9, 30vmax 60vmax 10vmax 10vmax #ffd461, 45vmax 60vmax 10vmax 10vmax #c089f8, 60vmax 60vmax 10vmax 10vmax #ff94c0, 75vmax 60vmax 10vmax 10vmax #9493f0, 90vmax 60vmax 10vmax 10vmax #feaa6b, 105vmax 60vmax 10vmax 10vmax #83d7fa, 120vmax 60vmax 10vmax 10vmax #fff, 135vmax 60vmax 10vmax 10vmax #5bdcb9",
                  "animation": `${keyframes} 2.5s linear infinite`
              },
              "& b:after": {
                  "content": "''",
                  "position": "absolute",
                  "top": "-2px",
                  "right": "-2px",
                  "bottom": "-2px",
                  "left": "-2px",
                  "display": "block",
                  "border": "4px solid black"
              },
            "@media (max-width: 767px)": {
              fontSize: "22px",
              maxWidth: "338px",
              lineHeight: "30px",
            },
          }}
        >
          {props.topicText}
        </Chakra.Box>
        <Chakra.Box
          as="span"
          color="var(--dl-color-gray-white)"
          width="100%"
          fontSize="22px"
          maxWidth="654px"
          fontStyle="normal"
          marginTop="38px"
          textAlign="center"
          fontWeight="300"
          lineHeight="30px"
          css={{
            "@media (max-width: 767px)": {
              fontSize: "14px",
              maxWidth: "338px",
              marginTop: "44px",
              lineHeight: "22px",
            },
          }}
        >
          {props.content}
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
})

IntroBlock.pressless = {
  name: "Intro Block",
  attributes: {
    topicText: {
      name: "Topic Text",
      type: "RichText",
      placeholder:
        "We arm brands with premium visual assets.",
    },
    content: {
      name: "Content",
      type: "RichText",
      placeholder:
        "We make visual content with old school service: pupil-dilating, spine-tingling, memory-making,  grin-inducing storytelling. Yep, all those good ol’ fashioned feels, served up in new and exciting ways to excite the mind, eye and heart.",
    },
  },
}
export default IntroBlock
