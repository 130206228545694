import React from "react"
import * as Chakra from "@chakra-ui/react"
import LayeredLogoSvg from "../../images/sutori-layered-logo.svg"
import { Parallax } from "../animation-helpers/animation-state"
import { useInView } from "react-intersection-observer"
import mergeRefs from "react-merge-refs"
import {ResponsiveVideoImage} from "../video-image";

interface BlocksheroProps {
  image?: any
  background_image?: any
  brandDetails?: string
  brand?: string
  topic_text?: string
  video_url?: string,
  mobile_background_image?: any
  mobile_video_url?: string
}

const Hero = React.forwardRef((props: BlocksheroProps, ref) => {
  const { ref: inViewRef, inView } = useInView({
    initialInView: true,
    threshold: 0.01,
  })


  const contentRef = React.useRef<HTMLDivElement>()

  return (
    <Chakra.Box
      ref={mergeRefs([ref, contentRef])}
      width="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      height="100vh"
      overflow="hidden"
      css={{
        marginTop: "-140px",
        "@media (max-width: 992px)": {
          marginTop: "-140px"
        },
        "@media (max-width: 767px)": {
          marginTop: "-100px"
        }
      }}
      sx={{
        scrollSnapAlign: "start",
      }}
    >
      <Chakra.Box
        ref={inViewRef}
        width="100%"
        display="flex"
        position="relative"
        alignItems="flex-start"
        flexDirection="column"
        opacity={inView ? 1 : 0}
        css={{
          "@media (max-width: 767px)": {
            opacity: "1!important",
          },
        }}
        transition="1s"
        height="100vh"
      >
        <ResponsiveVideoImage video_url={props?.video_url} mobile_video_url={props?.mobile_video_url} image={props?.image} mobile_background_image={props?.mobile_background_image} />
        <Chakra.Box
          left="0"
          right="0"
          width="100%"
          bottom="120px"
          display="flex"
          position="absolute"
          maxWidth="1440px"
          alignItems="center"
          marginLeft="auto"
          marginRight="auto"
          paddingLeft="58px"
          paddingRight="58px"
          flexDirection="column"
          justifyContent="center"
          css={{
            "@media (max-width: 991px)": {
              bottom: "59px",
              paddingLeft: "24px",
              paddingRight: "24px",
            },
          }}
        >
          <Chakra.Box
            width="100%"
            maxWidth="1134px"
            maxHeight="174px"
            objectFit="cover"
            css={{
              "@media (max-width: 991px)": {
                maxHeight: "52px",
                overflow: "unset",
              },
            }}
            overflow="hidden"
          >
            <Parallax y={[0, 200]} width="100%">
              <Chakra.Box
                width="100%"
                maxWidth="1134px"
                maxHeight="174px"
                objectFit="cover"
                css={{
                  "@media (max-width: 991px)": {
                    maxHeight: "52px",
                  },
                }}
              >
                <LayeredLogoSvg height="100%" width="auto" />
              </Chakra.Box>
            </Parallax>
          </Chakra.Box>
          <Chakra.Box
            width="100%"
            display="flex"
            marginTop="46px"
            alignItems="center"
            justifyContent="space-between"
            css={{
              "@media (max-width: 991px)": {
                marginTop: "106px",
                justifyContent: "center",
              },
            }}
          >
            <Chakra.Box
              as="span"
              color="#fff"
              fontSize="11px"
              fontFamily="TradeGothic LT Extended"
              lineHeight="12px"
              letterSpacing="1.8px"
              textTransform="uppercase"
              css={{
                "@media (max-width: 991px)": {
                  textAlign: "center",
                },
              }}
            >
              {props.brand}
            </Chakra.Box>
            <Chakra.Box
              as="span"
              color="#fff"
              fontSize="11px"
              fontFamily="TradeGothic LT Extended"
              lineHeight="12px"
              letterSpacing="1.8px"
              textTransform="uppercase"
              css={{
                "@media (max-width: 991px)": {
                  display: "none",
                },
              }}
            >
              {props.topic_text}
            </Chakra.Box>
          </Chakra.Box>
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
})

Hero.pressless = {
  name: "Hero",
  attributes: {
    topic_text: {
      name: "Brand Details",
      type: "RichText",
      placeholder: "Current Reel — Vans",
    },
    brand: {
      name: "Brand",
      type: "RichText",
      placeholder: "Cinema for your brand",
    },
    background_image: {
      name: "Background Image",
      type: "Image",
    },
    video_url: {
      name: "Video URL",
      description: "This will be used on desktop.",
      type: "PlainText",
    },
    mobile_background_image: {
      name: "Mobile Background Image",
      type: "Image",
    },
    mobile_video_url: {
      name: "Mobile Video URL",
      description: "This should be a vertical-format video.",
      type: "PlainText",
    },
  },
}
export default Hero
