import React from "react"
import BlockArea from "gatsby-plugin-pressless"
import * as Chakra from "@chakra-ui/react"
import { graphql } from "gatsby"
import ContactForm from "../components/blocks/contact-form"
import ContentSet from "../components/blocks/content-set"
import CtaGrid from "../components/blocks/cta-grid"
import Hero from "../components/blocks/hero"
import LayeredLogo from "../components/blocks/layered-logo"
import LogoGrid from "../components/blocks/logo-grid"
import MasonryGrid from "../components/blocks/masonry-grid"
import PageEndCta from "../components/blocks/page-end-cta"
import TextHero from "../components/blocks/text-hero"
import { SectionWithLogo } from "../components/blocks/section-with-logo"
import SEO from "gatsby-plugin-wpgraphql-seo"
import  HeroSlider from "../components/blocks/hero-slideshow"
import IntroBlock from "../components/blocks/intro-block";

const WpPage = ({ data, pageContext }) => {

  return (
    <Chakra.Box>
      <SEO post={data?.wpPage} />
        <BlockArea
            id="page"
            data={data?.wpPage}
            isEnabled={pageContext.isPressLess}
            direction="column"
          >
        <Hero />
        <SectionWithLogo />
        <ContactForm />
        <ContentSet />
        <CtaGrid />
        <LayeredLogo />
        <LogoGrid />
        <MasonryGrid />
        <PageEndCta />
        <TextHero />
        <HeroSlider/>
        <IntroBlock/>
      </BlockArea>
    </Chakra.Box>
  )
}

export default WpPage

export const query = graphql`
  query ($id: String) {
    site {
      buildTime
    }
    wpPage(id: { eq: $id }) {
      template {
        templateName
      }
      date(formatString: "LL")
      title
      presslesscontent
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
