import React from "react"
import * as Chakra from "@chakra-ui/react"
import CategorySelector from "../category-selector"
import CtaCategorySelector from "..//cta-grid-category-selector"
import Masonry from "react-responsive-masonry"
import VideoCard from "../video-card"
import PhotographyCard from "../photography-card"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useInView } from "react-intersection-observer"
import { ScrollFadeIn } from "../../utils/render-blocks"
import FadeTransition from "../transitions/fade-transition"
import { HeaderState } from "../header-state"

interface BlocksctaGridProps {
  options?: { image?: any; heading?: string; category?: string; logo?: any }[]
  buttonText?: string
  description?: string
  heading?: string
  category?: string
}

const CtaGrid = React.forwardRef((props: BlocksctaGridProps, ref) => {
  const data = useStaticQuery(graphql`
    query PostsQuery {
      site {
        buildTime
      }
      allWpPost(sort: {fields: date}) {
        nodes {
          title
          link
          date
          featuredImage {
            node {
              localFile {
                publicURL
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
          postGallery {
            fieldGroupName
            primaryContentType
          }
        }
      }
    }
  `)

  const [posts] = React.useState(data.allWpPost.nodes.sort((a,b) => Date.parse(b.date) - Date.parse(a.date)))
  const [categories] = React.useState(
    props.category
      ? posts.filter((post) =>
          post.categories.nodes.map((x) => x.name).includes(props.category)
        )
      : posts
  )




  const [visible, setVisible] = React.useState(8)

  const showMore = () => {

    setVisible((prevValue) => prevValue + 8)
  }


  const animation = Chakra.keyframes`
    0% {
      opacity: 0;
      transform: translateY(20px)
    }
    100% {
      opacity: 1;
      transform: translateY(0px)
    }
  `

  const { ref: inViewRef, inView } = useInView({ triggerOnce: true })

  return (
    <Chakra.Box ref={ref} width="100%" position="relative" background="#000">
      <ScrollFadeIn>
        <CtaCategorySelector />
      </ScrollFadeIn>
      <Chakra.Box
        ref={inViewRef}
        width="100%"
        display="flex"
        position="relative"
        background="#000"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Chakra.Box
          width="100%"
          display="flex"
          maxWidth="1440px"
          alignItems="flex-start"
          marginLeft="auto"
          paddingTop="65px"
          marginRight="auto"
          paddingLeft="58px"
          paddingRight="58px"
          flexDirection="row"
          paddingBottom="140px"
          css={{
            "@media (max-width: 991px)": {
              paddingTop: "35px",
              paddingLeft: "24px",
              paddingRight: "24px",
              flexDirection: "column",
              paddingBottom: "100px",
            },
          }}
        >
          <Chakra.Box
            width="40%"
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
            paddingRight="40px"
            css={{
              "@media (max-width: 991px)": {
                width: "100%",
                paddingBottom: "60px",
                paddingRight: "0",
              },
            }}
          >
            <Chakra.Box
              as="span"
              color="#fff"
              width="100%"
              fontSize="22px"
              maxWidth="335px"
              css={{
                "@media (max-width: 767px)": {
                  maxWidth: "100%"
                }
              }}
              lineHeight="30px"
              letterSpacing="1.1px"
              paddingBottom="40px"
            >
              {props.heading}
            </Chakra.Box>
            <Chakra.Box
              as="span"
              maxWidth="335px"
              color="#fff"
              fontSize="14px"
              fontWeight="300"
              lineHeight="22px"
              paddingBottom="60px"
              css={{
                "@media (max-width: 991px)": {
                  paddingBottom: "48px",
                  maxWidth: "100%"
                },
              }}
            >
              {props.description}
            </Chakra.Box>
            <Chakra.Button
              color="var(--dl-color-gray-black)"
              width="100%"
              height="auto"
              fontSize="13px"
              maxWidth="214px"
              background="transparent"
              maxHeight="40px"
              textAlign="center"
              lineHeight="15px"
              borderRadius="0px !important"
              paddingTop="12px"
              whiteSpace="nowrap"
              borderColor="var(--dl-color-gray-black)"
              paddingLeft="66px"
              paddingRight="66px"
              letterSpacing="1.3px"
              paddingBottom="12px"
              textTransform="uppercase"
              display="inline-block"
              padding="0.5rem 1rem"
              borderWidth="1px"
              backgroundColor="var(--dl-color-gray-white)"
              onClick={() => HeaderState.showDrawer()}
              transition="0.3s"
              _hover={{
                backgroundColor: "#fff4",
              }}
              css={{
                borderRadius: "0px",
                "@media (max-width: 767px)": {
                  width: "100%",
                  height: "auto",
                  maxWidth: "158px",
                  textAlign: "center",
                  paddingTop: "12px",
                  whiteSpace: "nowrap",
                  paddingLeft: "37px",
                  paddingRight: "37px",
                  paddingBottom: "12px",
                  animationPlayState: "40px",
                },
              }}
            >
              {props.buttonText}
            </Chakra.Button>
          </Chakra.Box>
          <Chakra.Box width="60%" display={{ base: "none", lg: "block" }}>
            <FadeTransition shouldChange={JSON.stringify(categories)}>
              <Chakra.Box as={Masonry} alignItems="flex-start" columnsCount={2} style={{minHeight:'100%', alignItems: "flex-start"}} gutter="18px">
                {categories
                  .slice(0, visible)
                  .map((category, idx) => {
                      if (category.postGallery.primaryContentType === "video") {
                        return (
                            <VideoCard
                                key={idx}
                                src={category.featuredImage.node?.localFile?.publicURL}
                                image={category.featuredImage.node}
                                title={category.title}
                                category={category.categories.nodes[0].name}
                                gallery={category.postGallery.gallery}
                                to={category.link}
                                type={category.postGallery.primaryContentType}
                                animation={inView && `${animation} 0.6s forwards`}
                                style={{animationDelay: `${(idx%8) * 300}ms`}}
                                opacity="0"
                            />
                        )
                      } else if (category.postGallery.primaryContentType === "gif") {
                        return (
                            <VideoCard
                                key={idx}
                                src={category.featuredImage.node?.localFile?.publicURL}
                                image={category.featuredImage.node}
                                title={category.title}
                                category={category.categories.nodes[0].name}
                                gallery={category.postGallery.gallery}
                                to={category.link}
                                type={category.postGallery.primaryContentType}
                                animation={inView && `${animation} 0.6s forwards`}
                                style={{animationDelay: `${(idx%8) * 300}ms`}}
                                opacity="0"
                            />
                        )
                      } else {
                        return (
                            <PhotographyCard
                                key={idx}
                                to={category.link}
                                src={category.featuredImage.node?.localFile?.publicURL}
                                image={category.featuredImage.node}
                                title={category.title}
                                category={category.categories.nodes[0].name}
                                gallery={category.postGallery.gallery}
                                type={category.postGallery.primaryContentType}
                                animation={inView && `${animation} 0.6s forwards`}
                                style={{animationDelay: `${(idx%8) * 300}ms`}}
                                opacity="0"
                            />
                        )
                      }
                    })}
              </Chakra.Box>
            </FadeTransition>
            {categories.length > 8 && categories[visible] !== undefined && (
              <Chakra.Button
                color="var(--dl-color-gray-black)"
                display='inline-block'
                marginLeft="auto"
                marginTop="100px"
                borderColor="var(--dl-color-gray-black)"
                marginRight="auto"
                paddingLeft="63px"
                borderRadius="4px"
                minWidth="214px"
                paddingRight="63px"
                flexDirection="row"
                paddingBottom="12px"
                onClick={(e) => showMore(e)}
                textTransform="uppercase"
                backgroundColor="var(--dl-color-gray-white)"
                padding="0.5rem 1rem"
                borderWidth="1px"
                textAlign="center"
                fontSize="13px"
                lineHeight="15.73px"
                letterSpacing="1.3px"
                cursor="pointer"
              >
                Load More
              </Chakra.Button>
            )}
          </Chakra.Box>
          <Chakra.Box width="100%" display={{ base: "block", lg: "none" }}>
            <Masonry columnsCount={1} >
              {categories
                .slice(0, visible)
                .map((category, idx) => {
                  if (category.postGallery.primaryContentType === "video") {
                    return (
                      <VideoCard
                          key={idx}
                          src={category.featuredImage.node?.localFile?.publicURL}
                          image={category.featuredImage.node}
                          title={category.title}
                          category={category.categories.nodes[0].name}
                          gallery={category.postGallery.gallery}
                          to={category.link}
                          type={category.postGallery.primaryContentType}
                      />
                  )
                } else if (category.postGallery.primaryContentType === "gif") {
                  return (
                      <VideoCard
                          key={idx}
                          src={category.featuredImage.node?.localFile?.publicURL}
                          image={category.featuredImage.node}
                          title={category.title}
                          category={category.categories.nodes[0].name}
                          gallery={category.postGallery.gallery}
                          to={category.link}
                          type={category.postGallery.primaryContentType}
                      />
                  )
                } else {
                  return (
                      <PhotographyCard
                          key={idx}
                          to={category.link}
                          src={category.featuredImage.node?.localFile?.publicURL}
                          image={category.featuredImage.node}
                          title={category.title}
                          category={category.categories.nodes[0].name}
                          gallery={category.postGallery.gallery}
                          type={category.postGallery.primaryContentType}
                      />
                  )
                }
              })}
            </Masonry>
            {categories.length > 8 && categories[visible] !== undefined && (
              <Chakra.Box width="100%" textAlign="center">
                <Chakra.Button
                    color="var(--dl-color-gray-black)"
                    display='inline-block'
                    marginLeft="auto"
                    marginTop="100px"
                    borderColor="var(--dl-color-gray-black)"
                    marginRight="auto"
                    paddingLeft="63px"
                    borderRadius="4px"
                    minWidth="214px"
                    paddingRight="63px"
                    flexDirection="row"
                    paddingBottom="12px"
                    onClick={(e) => showMore(e)}
                    textTransform="uppercase"
                    backgroundColor="var(--dl-color-gray-white)"
                    padding="0.5rem 1rem"
                    borderWidth="1px"
                    textAlign="center"
                    fontSize="13px"
                    lineHeight="15.73px"
                    letterSpacing="1.3px"
                    cursor="pointer"
                >
                  Load More
                </Chakra.Button>
              </Chakra.Box>
            )}
          </Chakra.Box>

        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
})

CtaGrid.pressless = {
  name: "Cta Grid",
  attributes: {
    options: {
      name: "Logos",
      type: "Repeater",
      attributes: {
        image: {
          name: "Image",
          type: "Image",
        },
        logo: {
          name: "Logo",
          type: "Image",
        },
        heading: {
          name: "Heading",
          type: "RichText",
          placeholder: "Victoria university",
        },
        category: {
          name: "Category",
          type: "RichText",
        },
      },
    },
    description: {
      name: "Description",
      type: "RichText",
      placeholder:
        "Every person in the world has something that defines them; something that makes them unique and individual to all others. It runs to the very \u2028core of their identity. A brand is no different. \u2028Every brand has its own unique identity; a voice that demands attention and a story that yearns \u2028to be told.",
    },
    category: {
      name: "Category",
      type: "PlainText",
    },
    heading: {
      name: "Heading",
      type: "RichText",
      placeholder:
        "Let’s leave your \u2028competition wondering \u2028what the hell happened.",
    },
    buttonText: {
      name: "Button",
      type: "RichText",
      placeholder: "Let’s talk",
    },
  },
}
export default CtaGrid
