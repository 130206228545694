import React from "react"
import * as Chakra from "@chakra-ui/react"

interface BlockscontentSetProps {
  topicText?: string
  content?: string
}

const ContentSet = React.forwardRef((props: BlockscontentSetProps, ref) => {
  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="var(--dl-color-gray-black)"
    >
      <Chakra.Box
        width="100%"
        margin="auto"
        display="flex"
        maxWidth="1440px"
        alignItems="center"
        paddingTop="87px"
        paddingLeft="58px"
        paddingRight="58px"
        flexDirection="column"
        paddingBottom="94px"
        justifyContent="center"
        backgroundColor="var(--dl-color-gray-black)"
        css={{
          "@media (max-width: 767px)": {
            paddingTop: "26px",
            paddingLeft: "28px",
            paddingRight: "28px",
            paddingBottom: "60px",
          },
          "@media (max-width: 479px)": {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        <Chakra.Box
          as="span"
          color="var(--dl-color-gray-white)"
          width="100%"
          fontSize="36px"
          maxWidth="800px"
          fontStyle="normal"
          textAlign="center"
          fontWeight="400"
          lineHeight="48px"
          letterSpacing="0.69px"
          css={{
            "@media (max-width: 767px)": {
              fontSize: "22px",
              maxWidth: "338px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "30px",
              letterSpacing: "0.42px",
            },
          }}
        >
          {props.topicText}
        </Chakra.Box>
        <Chakra.Box
          as="span"
          color="var(--dl-color-gray-white)"
          width="100%"
          fontSize="22px"
          maxWidth="654px"
          fontStyle="normal"
          marginTop="38px"
          textAlign="center"
          fontWeight="300"
          lineHeight="30px"
          css={{
            "@media (max-width: 767px)": {
              fontSize: "14px",
              maxWidth: "338px",
              marginTop: "44px",
              lineHeight: "22px",
            },
          }}
        >
          {props.content}
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
})

ContentSet.pressless = {
  name: "Content Set",
  attributes: {
    topicText: {
      name: "Topic Text",
      type: "RichText",
      placeholder:
        "We help brands achieve their full potential through the art of video and photography.",
    },
    content: {
      name: "Content",
      type: "RichText",
      placeholder:
        "We make visual content with old school service: pupil-dilating, spine-tingling, memory-making,  grin-inducing storytelling. Yep, all those good ol’ fashioned feels, served up in new and exciting ways to excite the mind, eye and heart.",
    },
  },
}
export default ContentSet
