import React from "react"
import * as Chakra from "@chakra-ui/react"
import { useInView } from "react-intersection-observer"
import mergeRefs from "react-merge-refs"

interface BlockstextHeroProps {
  image?: any
  text?: string
  video_url?: string
  mobile_background_image?: any
  mobile_video_url?: string
}

const TextHero = React.forwardRef((props: BlockstextHeroProps, ref) => {
  const { ref: inViewRef, inView } = useInView({
    initialInView: false,
    threshold: 0.01,
  })

  const [scale, setScale] = React.useState<number>(1)
  const [screen, setScreen] = React.useState<number>(0)
  const contentRef = React.useRef<HTMLDivElement>()

  const onScroll = () => {
    if (contentRef?.current && typeof window !== "undefined") {

      let box = contentRef?.current?.getBoundingClientRect()
      let screenY = window.innerHeight
      // More easing funcs found at: https://gist.github.com/gre/1650294
      let easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t)
      let scale = Math.abs(1 - (box.top + screenY) / screenY).toFixed(3)
      const SIZE_SCALE = 5 // formula = 100 / desired_increase  (ie: 100 / 20 = 5)
      let newScale = Math.max(1, easeInOutQuad(scale) / SIZE_SCALE + 1)
      setScale(newScale)
      setScreen(window.innerWidth / screen)
    }
  }

  const setHeight = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    onScroll()
  }

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll)
    window.addEventListener("resize", onScroll)
    onScroll()
    setHeight()

    return () => {
      window.removeEventListener("scroll", onScroll)
      window.removeEventListener("resize", onScroll)
    }
  }, [])

  return (
    <Chakra.Box
      ref={mergeRefs([ref, contentRef])}
      width="100%"
      display="flex"
      position="relative"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      height="100vh"
      overflow="hidden"
      css={{
        scrollSnapAlign: "start",
      }}
    >
      <Chakra.Box
        ref={inViewRef}
        width="100%"
        height="100%"
        opacity={inView ? 1 : 0}
        transition="1s"
        css={{
          "@media (max-width: 767px)": {
            opacity: "1!important",
          },
        }}
      >
        <Chakra.Box
          width="100%"
          height="100%"
          filter={props.image.filter}
          backgroundImage={props.image.backgroundImage}
          className={props.image.className}
          style={props.image.style}
        >
          <Chakra.Box
              className={props.image.className}
              filter={props.image.filter}
              //height="100%"
              height="100vh"
              transition="filter 0.5s, transform 0.2s"
              css={{
                "@media (max-width: 767px)": {
                  filter: "0.5s",
                  display: props?.mobile_video_url ? "none" : "unset"
                },
              }}
              backgroundRepeat="no-repeat"
              backgroundImage={props?.image?.src}
              style={{ transform: `scale(${scale})`, transition: "transform 0.5s", willChange: "transform" }}
              alt={props.image.alt}
              as={props.video_url ? "video" : "div"}
              backgroundSize="cover"
              backgroundPosition="center center"
              id="background-video"
              objectFit="cover"
              objectPosition="center center"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              playsInline
              autoPlay
              loop
              muted
              width="100%"
          >
            {props.video_url && (
                <>
                  <source src={props?.video_url} type="video/mp4" />
                  <source src={props?.video_url} type="video/ogg" />
                  Your browser does not support the video tag.
                </>
            )}
          </Chakra.Box>
          {props?.mobile_video_url && <Chakra.Box
              className={props.mobile_background_image.className}
              filter={props.mobile_background_image.filter}
              //height="100%"
              height="100vh"
              transition="filter 0.5s, transform 0.2s"
              display="none"
              css={{
                "@media (max-width: 767px)": {
                  filter: "0.5s",
                  display: "unset"
                },
              }}
              backgroundRepeat="no-repeat"
              backgroundImage={props?.mobile_background_image?.src}
              style={{ transform: `scale(${scale})` }}
              alt={props.mobile_background_image.alt}
              as={props.mobile_video_url ? "video" : "div"}
              backgroundSize="cover"
              backgroundPosition="center center"
              id="background-video"
              objectFit="cover"
              objectPosition="center center"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              playsInline
              autoPlay
              loop
              muted
              width="100%"
          >
            {props.video_url && (
                <>
                  <source src={props?.video_url} type="video/mp4" />
                  <source src={props?.video_url} type="video/ogg" />
                  Your browser does not support the video tag.
                </>
            )}
          </Chakra.Box>}
        </Chakra.Box>
      </Chakra.Box>
      <Chakra.Box
        width="100%"
        bottom="124"
        display="flex"
        position="absolute"
        maxWidth="1440px"
        alignItems="center"
        marginLeft="auto"
        marginRight="auto"
        paddingLeft="58px"
        paddingRight="58px"
        flexDirection="column"
        justifyContent="center"
        css={{
          "@media (max-width: 991px)": {
            paddingLeft: "28px",
            paddingRight: "28px",
          },
          "@media (max-width: 767px)": {
            bottom: "75px",
            maxWidth: "100%",
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        <Chakra.Box
          as="span"
          color="#fff"
          width="100%"
          fontSize="36px"
          fontStyle="normal"
          textAlign="center"
          fontWeight="400"
          lineHeight="48px"
          letterSpacing="0.69px"
          css={{
            "@media (max-width: 767px)": {
              fontSize: "22px",
              maxWidth: "100%",
              lineHeight: "30px",
              letterSpacing: "0.42px",
            },
            "@media (max-width: 479px)": {
              width: "auto",
            },
          }}
        >
          {props.text}
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
})

TextHero.pressless = {
  name: "Text Hero",
  attributes: {
    image: {
      name: "Background Image",
      type: "Image",
    },
    video_url: {
      name: "Video URL",
      type: "PlainText",
    },
    mobile_background_image: {
      name: "Mobile Background Image",
      type: "Image",
    },
    mobile_video_url: {
      name: "Mobile Video URL",
      description: "This should be a vertical-format video.",
      type: "PlainText",
    },
    text: {
      name: "Text",
      type: "RichText",
      placeholder:
        "We help brands achieve their full potential through the art of video and photography.",
    },
  },
}
export default TextHero
